import React from 'react';
import Styles from './footer.module.scss';
import Link from "../link/link";

export default props => {
  return (
    <footer className={`container pt-5 pb-4 ${Styles.footer}`}>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4 mb-lg-0">
          <h2>Psychotherapie Weber</h2>
          <address className="mb-1">
            Steinweg 30<br></br>
            57627 Hachenburg<br></br>
            Deutschland
          </address>
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-4 mb-lg-0">
          <h2>Kontakt</h2>
          <ul className="list-unstyled m-0">
            <li>
              Tel.: <a className="unstyled" href="tel:+4926629502797">+49 2662 9502 797</a>
            </li>
            <li>
              <a className="unstyled" href="mailto:info@psychotherapie-weber.net">info@psychotherapie-weber.net</a>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-6 col-lg-3 mb-3 mb-md-0">
          <h2>Bereiche</h2>
          <ul className="list-unstyled m-0">
            <li>
              <Link className="unstyled"
                    to="/ueber-mich">
                Über mich
              </Link>
            </li>
            <li>
              <Link className="unstyled"
                    to="/einzeltherapie">
                Einzeltherapie
              </Link>
            </li>
            <li>
              <Link className="unstyled"
                    to="/gruppentherapie">
                Gruppentherapie
              </Link>
            </li>
            <li>
              <Link className="unstyled"
                    to="/behandlungsspektrum">
                Behandlungsspektrum
              </Link>
            </li>
            <li>
              <Link className="unstyled"
                    to="/kontakt">
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <h2>Rechtliches</h2>
          <ul className="list-unstyled m-0">
            <li>
              <Link className="unstyled"
                    to="/impressum">
                Impressum
              </Link>
            </li>
            <li>
              <Link className="unstyled"
                    to="/datenschutz">
                Datenschutz
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="my-md-4"/>
          <a className={`unstyled ${Styles.expoLink}`} href="https://www.exportarts.io" rel="noopener nofollow">
            Made with
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>
            </svg>
            by exportarts.io
          </a>
        </div>
      </div>
    </footer>
  );
};
