import React from 'react';
import Styles from './contact.module.scss';

export default props => {
  return (
    <div className={`container pt-5 pb-0 pb-md-5`}>
      <div className={`row`}>
        <div className={`col position-relative ${Styles.cardBackground}`}>
          <div className={`row d-block d-md-flex justify-content-end pl-3 pl-md-0 pr-3 pr-md-4 ${Styles.contactWrapper}`}>
            <div className={`col-12 col-md-9 col-xl-7 bg-white mb-0 mb-md-4 p-0 p-md-5`}>
              <ul className="list-unstyled py-2">
                <li className={`pb-4 ${Styles.contactText}`}>
                  <a className="d-flex align-items-center unstyled" href="tel:+4926629502797">
                    <i className={`material-icons color-green ${Styles.icon}`}>phone</i>
                    <span className={`ml-3 ml-md-4 color-black font-weight-light ${Styles.contactText}`}>+49 2662 9502 797</span>
                  </a>
                </li>
                <li className={`d-flex align-items-center py-4 ${Styles.contactText}`}>
                  <a className="d-flex align-items-center unstyled" href="mailto:info@psychotherapie-weber.net">
                    <i className={`material-icons color-green ${Styles.icon}`}>mail</i>
                    <span className={`ml-3 ml-md-4 color-black font-weight-light ${Styles.contactText}`}>info@psychotherapie-weber.net</span>
                  </a>
                </li>
                <li className={`d-flex align-items-center pt-4`}>
                  <i className={`material-icons color-green ${Styles.icon}`}>near_me</i>
                  <span className={`ml-3 ml-md-4 color-black font-weight-light ${Styles.contactText}`}>Steinweg 30, 57627 Hachenburg</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
