import React, { useEffect, useState } from 'react';
import Link from '../link/link';
import Styles from './navigation.module.scss';

const ROUTES = [
  {
    link: '/ueber-mich',
    name: 'Über mich'
  },
  {
    link: '/einzeltherapie',
    name: 'Einzeltherapie'
  },
  {
    link: '/gruppentherapie',
    name: 'Gruppentherapie'
  },
  {
    link: '/behandlungsspektrum',
    name: 'Behandlungsspektrum'
  },
  {
    link: '/kontakt',
    name: 'Kontakt'
  }
];

export default props => {
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const closeNavbar = () => {
    document.body.classList.remove('no-scroll');
    setNavbarOpen(false);
  };

  const openNavbar = () => {
    document.body.classList.add('no-scroll');
    setNavbarOpen(true);
  };

  const toggleNavbar = () => {
    if (isNavbarOpen) {
      closeNavbar();
    } else {
      openNavbar();
    }
  };

  const closeNavbarOnResize = () => {
    if (isNavbarOpen && window.innerWidth >= 1200) {
      closeNavbar();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', closeNavbarOnResize);
  }, []);

  return (
    <nav className={`navbar navbar-expand-xl navbar-light bg-white ${Styles.nav}`}>
      <div className="container">

        <Link className={`navbar-brand d-flex align-items-center ${Styles.navbarSpacing}`} to="/">
          <img src="/images/global/logo.svg" height="52" alt="Psychotherapie Weber"/>
        </Link>

        <button className="navbar-toggler border-0 outline-none" onClick={() => toggleNavbar()}>
          <i className="color-black material-icons fade-in">{isNavbarOpen ? 'close' : 'menu'}</i>
        </button>

        <div className={`collapse navbar-collapse my-5 my-xl-0 ${isNavbarOpen ? 'show' : ''}`}>
          <div className="w-100 d-flex justify-content-start justify-content-xl-end">
            <ul className="navbar-nav">
              {
                ROUTES.map((route, index) => (
                  <li key={index} className="my-3 my-xl-0">
                    <Link className="unstyled p-0 ml-xl-5 ml-0 text-uppercase color-black" activeClassName={`${Styles.selected}`}
                          to={route.link}>
                      {route.name}
                    </Link>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>

      </div>
    </nav>
  )
};
